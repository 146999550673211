<template>
  <div class="user-not-login">
    <form class="login-form">
      <BaseInput
        ref="username"
        v-model="formData.username"
        name="username"
        placeholder="Tên đăng nhập"
        :limit-input="+$config.MAX_LENGTH_USER_NAME"
        special-character
        :error-special-character="FORM_AUTH.VALIDATE_USERNAME"
        @keyup.enter="password?.focus()"
        @focus="isShowRequire = false"
      />
      <BaseInput
        ref="password"
        v-model.trim="formData.password"
        name="password"
        type="password"
        is-hide-eye
        placeholder="Mật khẩu"
        :limit-input="+$config.MAX_LENGTH_PASSWORD"
        :allow-validate-password="false"
        @keyup.enter="handleClickEnter"
        @focus="isShowRequire = false"
      >
        <div class="forgot-password" @click="onForgotPassword()">
          <BaseImg src="assets/images/common/forgot-pass.svg" alt="icon-forgot" class="icon-forgot" />
        </div>
      </BaseInput>
      <div v-if="isShowRequire" class="require-input">
        <span>
          {{ !formData.username ? 'Yêu cầu nhập tên đăng nhập' : 'Yêu cầu nhập mật khẩu' }}
        </span>
      </div>
    </form>
    <BaseButton id="login_btn" class="btn-login base-button--secondary" @click="handleSubmit">Đăng nhập</BaseButton>
    <BaseButton
      id="register_btn"
      class="base-button--primary btn-register"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng ký
    </BaseButton>
  </div>
</template>
<script setup lang="ts">
import { FORM_AUTH } from '~/constants/form/auth'
import { useHeader } from '~/composables/useHeader'
import BaseInput from '~/components/common/base-input.vue'
import useModal from '~/composables/useModal'
import { useLogin } from '~/composables/user/useLogin'
const BaseButton = defineAsyncComponent(() => import('~/components/common/base-button.vue'))
const { onClickAuthor, MODAL_TYPES } = useHeader()
const { $config } = useNuxtApp()
const { openModal, closeAllModal } = useModal()
const route = useRoute()
const isShowRequire = ref<boolean>(false)
const { username, password, submit, formData, isLoading } = useLogin()
const handleKeyPress = (event: { key: string }) => {
  if (event.key === 'Enter') {
    handleSubmit()
  }
}
const disabledBtn = computed(() => {
  return !formData.username || !formData.password
})
const handleSubmit = () => {
  if (!formData.username || !formData.password) {
    isShowRequire.value = true
    setTimeout(() => {
      isShowRequire.value = false
    }, 3000)
  } else {
    submit()
  }
}
const handleClickEnter = (event: any) => {
  event.target.blur()
}
const onForgotPassword = () => {
  navigateTo({
    path: location.pathname,
    query: { ...route.query, popup: 'forgot-password' }
  })
  openModal(MODAL_TYPES.LOGIN)
}
onMounted(() => {
  window.addEventListener('keyup', handleKeyPress)
})
onUnmounted(() => {
  window.removeEventListener('keyup', handleKeyPress)
})
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-not-login.scss" />
